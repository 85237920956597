<template>
 <b-card title="محركات البحث">
    <b-tabs>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-classical-course' ||currentRouteName=='seo-online-course'
          ||currentRouteName=='seo-course-in-city'
        "
        @click="$router.push('/seo-classical-course')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-classical-course"
            class="no-color"
          >
            <feather-icon icon="HomeIcon" />
            <span>سيو الدورات</span>
          </a>
        </template>

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-classical-category' ||currentRouteName=='seo-online-category' "
        @click="$router.push('/seo-classical-category')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-classical-category"
            class="no-color"
          >
            <feather-icon icon="FolderIcon" />
            <span>سيو الفئات</span>
          </a>
        </template>

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-city'"
        @click="$router.push('/seo-city')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-city"
            class="no-color"
          >
            <feather-icon icon="FolderIcon" />
            <span>سيو المدن</span>
          </a>
        </template>

        <!-- <SeoCity></SeoCity> -->

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-category-in-city'"
        @click="$router.push('/seo-category-in-city')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-category-in-city"
            class="no-color"
          >
            <feather-icon icon="FolderIcon" />
            <span>سيو الفئة في المدن</span>
          </a>
        </template>

        <!-- <SeoCategryInCity></SeoCategryInCity> -->

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin'"
        :active="currentRouteName=='seo-pages'"
        @click="$router.push('/seo-pages')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-pages"
            class="no-color"
          >
            <feather-icon icon="FolderIcon" />
            <span>سيو الصفحات</span>
          </a>
        </template>

        <!-- <SeoPages></SeoPages> -->

      </b-tab>
      <b-tab
        v-if=" role.roles[0]=='Supervisor' || role.roles[0]=='Super Admin' ||  role.roles[0]=='Blogger' "
        :active="currentRouteName=='seo-blog'"
        @click="$router.push('/seo-blog')"
      >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/seo-blog"
            class="no-color"
          >
            <feather-icon icon="FolderIcon" />
            <span>سيو المقال</span>
          </a>
        </template>

        <BlogSeo v-if=" role.roles[0]=='Blogger' " />

        <!-- <SeoPages></SeoPages> -->

      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <SeoClassicalCourse v-if="route.name === 'seo-classical-course' && role.roles[0]==='Supervisor' || role.roles[0]=='Super Admin'" />

      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'
import SeoClassicalCourse from './seocourse/SeoClassicalCourse.vue'
import CategorySeo from './seocategory/CategorySeo.vue'
import BlogSeo from './seoblog/BlogSeo.vue'
import SeoCity from './SeoCity.vue'
import SeoCategryInCity from './SeoCategryInCity.vue'
import SeoPages from './SeoPages.vue'

export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    SeoClassicalCourse, // CategoriesList,

    CategorySeo,
    BlogSeo,
    SeoCity,
    SeoCategryInCity,
    SeoPages,
  },
  data() {
    return {
      role: {},
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  created() {
    this.role = JSON.parse(localStorage.getItem('userData'))
  }
  ,
}
</script>
