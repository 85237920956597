<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        مقالات سيو
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="النوع "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="showList"
                  :reduce="(val) => val.id"
                  @input="getPost(addCourseForm.type)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label=" مقال / خبر"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name=""
                rules="required"
              >
                <v-select
                  id="blog-edit-caيtegory"
                  v-model="addCourseForm.article"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="articleList"
                  :reduce="(val) => val.id"
                  @input="getPostSeo(addCourseForm.article)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="العنوان"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الوصف"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="الكلمات المفتاحية"
              label-for="blog-content"
              class="mb-2"
            >

              <b-form-tags v-model="addCourseForm.keyword" />

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="width: 100%">
      <blog ref="table" />
    </b-row>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BFormTags,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import Blog from './BlogHomeSEOList.vue'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const articleList = ref([])
    const table = ref()
    const form = ref()

const addCourseForm = reactive({

  article: '',
  title: '',
  description: '',
  keyword: '',
  type: '',

})
    const getPost = id => {
      addCourseForm.article = ''
      articleList.value = []
      store.dispatch('blog/getAllBlogList', {
        'filter[type]': id,

      }).then(response => {
        console.log('getAllBlog', response.data.data.data)
        articleList.value = response.data.data.data
      })
    }
    const getPostSeo = id => {
      if (id) {
        store.dispatch('blog/getAllBlogSEO', {
          'filter[post_id]': id,

        }).then(response => {
          console.log('getAllBlog', response.data.data)
          if (response?.data.data.data.length > 0) {
            addCourseForm.keyword = response?.data.data.data[0].keywords.split(',')
            addCourseForm.title = response?.data.data.data[0].title
            addCourseForm.description = response?.data.data.data[0].description
          } else {
            addCourseForm.keyword = ''
            addCourseForm.title = ''
            addCourseForm.description = ''
          }
        })
      } else {
        addCourseForm.keyword = ''
        addCourseForm.title = ''
        addCourseForm.description = ''
      }
    }
    const getCoursesByCategory = id => {
      addCourseForm.course = ''
      coursesList.value = []
      store.dispatch('categories/getCoursesByCategory', {

        online: 0,
        ' category_id': id,

      })
        .then(response => {
          console.log('response', response.data)
          coursesList.value = response.data.data
        })
    }
    const showList = ref([{
      id: 2,
      label: 'News',
    },
    {
      id: 1,
      label: 'Articles',
    },

    ])
 
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      console.log(addCourseForm)
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('post_id', addCourseForm.article)
          formData.append('type', 1)
          formData.append('title', addCourseForm.title)
          formData.append('name', addCourseForm.name)
          formData.append('description', addCourseForm.description)
          formData.append('keywords', addCourseForm.keyword)

          store
            .dispatch('seo/postSeo', formData)
            .then(response => {
              addCourseForm.article = ''
              addCourseForm.type = ''
              table.value.GetAllCourses()
              form.value.reset()
              Vue.swal({
                title: ' Seo Article ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addCourseForm)
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'ID' },
      { key: 'name', label: ' Course Name' },
      { label: 'Title', key: 'link_id' },
      { key: 'name', label: 'Descripation' },
      { label: 'KeyWords', key: 'Date' },

      { key: 'actions' },
    ]
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      showList,
      inputImageRendererTab,
      Tabimage,
      getPost,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      articleList,
      getPostSeo,
      table,
      form,
      getCoursesByCategory,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    Blog,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTags,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>

  .list-tabledata {
    margin: 16px;
}

  .mt-50.col-12 {
    margin: 12px 0px;
}

</style>
