<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="بحث..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCourseListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :items="GetAllPages"
        striped
        show-empty
        empty-text="  يتم جلب البيانات"
      >
        <!-- Column: Status -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'update-seo-pages', params: { id: data.item.id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <b-dropdown-item-button @click="DeleteItem(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BDropdownItemButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, reactive,
} from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'

export default {
  components: {
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BDropdownItemButton,
    vSelect,
  },
  setup() {
    const coursesList = ref([])
    const meta = reactive({})
    const perPageOptions = [10, 25, 50, 100, 1]
    const perPage = ref(10)
    const refCourseListTable = ref(null)
    const totalItems = ref(0)
    const searchQuery = ref('')
    const currentPage = ref(1)

    const refetchData = () => {
      refCourseListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    const GetAllPages = (ctx, callback) => {
      store
        .dispatch('seo/getAllSeoPages', {
          'filter[search]': searchQuery.value,

          per_page: perPage.value,
          page: currentPage.value,
        })
        .then(response => {
          console.log('resprrrronse', response.data)
          // coursesList.value=response.data.data
          const { data } = response.data
          // totalItems = response.data.meta.total;
          console.log('totalItems', totalItems)

          callback(data)
        })
    }

    const dataMeta = computed(() => {
      const localItemsCount = refCourseListTable.value
        ? refCourseListTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems,
      }
    })
    // GetAllPages()
    const DeleteItem = id => {
      Vue.swal({
        icon: 'warning',
        title: 'هل انت متاكد من الحذف ?',
        confirmButtonText: 'نعم',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('seo/DeleteSeoCategory', id).then(response => {
            refetchData()
            Vue.swal({
              title: 'تم الحذف  ',
              confirmButtonText: 'حسنا',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    }
    const tableColumns = [
      { key: 'id', label: 'الرقم', sortable: true },
      { key: 'page_name', label: 'اسم الصفحة ', sortable: true },

      { key: 'actions', label: 'الخيارات' },
    ]

    return {
      tableColumns,
      coursesList,
      DeleteItem,
      meta,
      perPageOptions,
      totalItems,
      currentPage,
      perPage,
      dataMeta,
      refCourseListTable,
      refetchData,
      GetAllPages,
      searchQuery,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
